import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  isAfter,
  isBefore
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  fetchRecurringEmails,
  createRecurringEmail,
  editRecurringEmail,
  openEmailDrawer,
  closeEmailDrawer,
  toggleActiveRecurringEmail
} from '@redux/actions';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  isLoadingRecurringEmailsSelector,
  recurringEmailSelector,
  showEmailDrawerSelector
} from '@redux/selectors';
import { FilterOptionsProps } from '@utils/api';
import { RecurringEmailProps, ToggleStatusProps } from '@redux/prop-types';
import Input from '@ui/Input';
import { ColumnTypes } from '../../../Table/types';
import Table from '../../../Table';
import Drawer from '../../../ui/Drawer';
import Button from '../../../ui/Button';
import { ButtonTypes } from '../../../ui/Button/button-types';
import SwapVertical from '../../../../assets/icons/swap-vertical';
import Visibility from '../../../../assets/icons/visibility';
import Plus2 from '../../../../assets/icons/plus2';
import Magnifier from '../../../../assets/icons/magnifier';
import { FetchEmailsProps } from '../types';
import {
  ChangeLog,
  RecurringEmail as Email,
  EmailRecipients,
  RecurringEmail,
  RecurringOption,
  FinishAt,
  CustomRecurringOption
} from './types';
import RecurringEmailForm from './components/recurring-email-form';
import DrawerRecipients from './components/drawer-recipients';

type RecurringEmailsProps = {
  readonly recurringEmails: RecurringEmailProps;
  readonly showEmailDrawer: boolean;
  readonly isLoadingRecurringEmails: boolean;
  readonly fetchRecurringEmails: (props: FilterOptionsProps) => void;
  readonly createRecurringEmail: (data: Email) => void;
  readonly toggleActiveRecurringEmail: (props: ToggleStatusProps) => void;
  readonly editRecurringEmail: (data: Email) => void;
  readonly openEmailDrawer: () => void;
  readonly closeEmailDrawer: () => void;
};

const initialFilterOptions: FilterOptionsProps = {
  pageNumber: 1,
  pageSize: 5,
  search: '',
  sort: 'startAt',
  order: 'DESC'
};

const mapStateToProps = createSelector(
  recurringEmailSelector,
  showEmailDrawerSelector,
  isLoadingRecurringEmailsSelector,
  (
    recurringEmails: RecurringEmailProps,
    showEmailDrawer: boolean,
    isLoadingRecurringEmails: boolean
  ) => ({
    recurringEmails,
    showEmailDrawer,
    isLoadingRecurringEmails
  })
);

const mapDispatchtoProps = {
  fetchRecurringEmails,
  createRecurringEmail,
  toggleActiveRecurringEmail,
  editRecurringEmail,
  openEmailDrawer,
  closeEmailDrawer
};

const verifyOnDate = (today: Date, finishAt: FinishAt) => {
  if (!finishAt.onDate) {
    return 'Vigente';
  }

  const finishDate = new Date(finishAt?.onDate);
  if (isBefore(today, finishDate)) {
    return 'Vigente';
  } else {
    return 'Expirado';
  }
};

const verifyOnCount = (
  today: Date,
  startAt: Date,
  recurringInterval: number,
  finishAt: FinishAt,
  recurringType: CustomRecurringOption
) => {
  const occurrencesCount = finishAt.onCount ?? 1;

  let recurrenceEndDate;
  switch (recurringType) {
    case 'day':
      recurrenceEndDate = addDays(
        startAt,
        occurrencesCount * recurringInterval
      );
      break;
    case 'week':
      recurrenceEndDate = addWeeks(
        startAt,
        occurrencesCount * recurringInterval
      );
      break;
    case 'month':
      recurrenceEndDate = addMonths(
        startAt,
        occurrencesCount * recurringInterval
      );
      break;
    default:
      recurrenceEndDate = startAt;
      break;
  }

  if (isBefore(today, recurrenceEndDate)) {
    return 'Vigente';
  } else {
    return 'Expirado';
  }
};

const getStatusLabel = (
  isActive: boolean,
  _startAt: Date,
  recurring: RecurringOption
): string => {
  const today = new Date();
  const startAt = new Date(_startAt);

  if (!isActive) {
    return 'Desativado';
  }

  if (isBefore(today, startAt)) {
    return 'Pendente';
  }

  if (recurring.type !== 'custom' && isAfter(today, startAt)) {
    return 'Vigente';
  }

  const {
    finishAt,
    recurringInterval = 1,
    recurringType = 'day'
  } = recurring.custom || {};

  if (finishAt?.type === 'on-date') {
    return verifyOnDate(today, finishAt);
  }

  if (finishAt?.type === 'on-count') {
    return verifyOnCount(
      today,
      startAt,
      recurringInterval,
      finishAt,
      recurringType
    );
  }

  return 'Vigente';
};

const renderStatus:
  | ((column: ColumnTypes<Email>, item: Email) => void)
  | undefined = (_, { isActive, startAt, recurring }: RecurringEmail) => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {getStatusLabel(isActive, startAt, recurring)}
    </span>
  );
};

function RecurringEmails({
  recurringEmails,
  showEmailDrawer,
  isLoadingRecurringEmails,
  fetchRecurringEmails,
  createRecurringEmail,
  toggleActiveRecurringEmail,
  editRecurringEmail,
  openEmailDrawer,
  closeEmailDrawer
}: RecurringEmailsProps): JSX.Element {
  const [isOpenRecipients, setIsOpenRecipients] = useState(false);
  const [currentRecipients, setCurrentRecipients] =
    useState<EmailRecipients | null>(null);
  const [currentEmail, setCurrentEmail] = useState<Email | null>(null);
  const [isOpenChangeLog, setIsOpenChangeLog] = useState(false);
  const [currentChangeLog, setCurrentChangeLog] = useState<ChangeLog[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] =
    useState<FilterOptionsProps>(initialFilterOptions);
  const [changeLogPage, setChangeLogPage] = useState(1);

  const fetchEmails = useCallback(
    (props?: FetchEmailsProps) => {
      setFilter(old => {
        try {
          fetchRecurringEmails({
            ...old,
            ...props
          });
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }

        return {
          ...old,
          ...props
        };
      });
    },
    [fetchRecurringEmails]
  );

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  const handleSort = (column: string) => {
    if (filter.sort === column) {
      fetchEmails({
        sort: column,
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      });
      return;
    }

    fetchEmails({
      sort: column,
      order: 'ASC'
    });

    setFilter({ ...filter, sort: column });
  };

  const handleOpenModalRecipients = (recipients: EmailRecipients) => {
    setCurrentRecipients(recipients);
    setIsOpenRecipients(true);
  };

  const handleCloseModalRecipients = () => {
    setIsOpenRecipients(false);
  };

  const handleOpenModalActions = (email: Email | null) => {
    setCurrentEmail(email);
    openEmailDrawer();
  };

  const handleCloseModalActions = () => {
    setCurrentEmail(null);
    closeEmailDrawer();
  };

  const handleOpenModalChangeLog = (changeLog: ChangeLog[]) => {
    setCurrentChangeLog(changeLog);
    setIsOpenChangeLog(true);
  };

  const handleCloseModalChangeLog = () => {
    setIsOpenChangeLog(false);
  };

  function getChangeLog(props?: FetchEmailsProps) {
    const pageNumber = props?.pageNumber ?? 1;
    setChangeLogPage(pageNumber);
  }

  const handleSubmitSearch = () => {
    void fetchEmails({ search: filter.search, pageNumber: 1 });
    setCurrentPage(1);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, search: event.target.value });
  };

  const handleToggleStatus = (isActive: boolean, id: string) => {
    toggleActiveRecurringEmail({ isActive, id });
  };

  const tableColumnsEmail: ColumnTypes<Email>[] = [
    {
      key: 'subject',
      title: (
        <button key='order-by-subject' onClick={() => handleSort('subject')}>
          <span>Assunto</span>
          <SwapVertical />
        </button>
      ),
      width: 136,
      render: (_, { subject }) => (
        <span
          title={subject}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '200px'
          }}
        >
          {subject}
        </span>
      )
    },
    {
      key: 'status',
      title: (
        <button key='order-by-status' onClick={() => handleSort('status')}>
          <span>Status</span>
          <SwapVertical />
        </button>
      ),
      width: 185,
      render: renderStatus
    },
    {
      key: 'createdAt',
      title: (
        <button
          key='order-by-createdAt'
          onClick={() => handleSort('createdAt')}
        >
          <span>Data de cadastro</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { createdAt }) => format(new Date(createdAt), 'dd/MM/yyyy')
    },
    {
      key: 'start-at',
      title: (
        <button key='order-by-start-at' onClick={() => handleSort('startAt')}>
          <span>Início em</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { startAt }) => format(new Date(startAt), 'dd/MM/yyyy')
    },
    {
      key: 'recurringDescription',
      title: (
        <button
          key='order-by-recurringDescription'
          onClick={() => handleSort('recurringDescription')}
        >
          <span>Recorrência</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { recurringDescription }) => recurringDescription
    },
    {
      key: 'on-date',
      title: (
        <button
          key='order-by-finishOnDate'
          onClick={() => handleSort('onDate')}
        >
          <span>Finaliza em</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { recurring }) => {
        const date = new Date(
          recurring?.custom?.finishAt?.onDate ?? new Date()
        );

        if (recurring?.custom?.finishAt?.type === 'never') {
          return 'Nunca';
        } else if (recurring?.custom?.finishAt?.type === 'on-date') {
          return format(date, 'dd/MM/yyyy');
        } else if (recurring?.custom?.finishAt?.type === 'on-count') {
          return `${recurring?.custom?.finishAt?.onCount ?? ''} ocorrência(s)`;
        } else {
          return 'Nunca';
        }
      }
    },
    {
      key: 'recipients',
      title: (
        <button key='order-by-recipients'>
          <span>Destinatário(s)</span>
        </button>
      ),
      width: 120,
      render: (_, { recipients }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Visibility onClick={() => handleOpenModalRecipients(recipients)} />
        </div>
      )
    },
    {
      key: 'change-log',
      title: (
        <button key='order-by-change-log'>
          <span>Histórico de alterações</span>
        </button>
      ),
      width: 220,
      render: (_, { changeLog }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Visibility onClick={() => handleOpenModalChangeLog(changeLog)} />
        </div>
      )
    },
    {
      key: 'action',
      title: (
        <span
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'block',
            paddingRight: 12
          }}
        >
          Ações
        </span>
      ),
      width: 150,
      render: (_, email) => (
        <div className='action-icons'>
          <div className='action-icon'>
            <Visibility onClick={() => handleOpenModalActions(email)} />
          </div>
        </div>
      )
    }
  ];

  const tableColumnsChangeLog: ColumnTypes<ChangeLog>[] = [
    {
      key: 'date',
      title: 'Data',
      render: (_, { date }) => (
        <span>
          {date && format(new Date(date), 'dd/MM/yyyy', { locale: ptBR })}
        </span>
      )
    },
    {
      key: 'time',
      title: 'Hora',
      render: (_, { date }) => (
        <span>{date && format(new Date(date), 'HH:mm', { locale: ptBR })}</span>
      )
    },
    {
      key: 'author',
      title: 'Autor(a)'
    }
  ];

  return (
    <>
      <div className='header-actions' style={{ gap: 16 }}>
        <div className='search-area'>
          <Input
            placeholder='Pesquisar email...'
            insideIcon={
              <Magnifier
                style={{ cursor: 'pointer' }}
                width={20}
                height={20}
                onClick={handleSubmitSearch}
              />
            }
            value={filter.search}
            onChange={handleChangeSearch}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                handleSubmitSearch();
              }
            }}
          />
        </div>

        <Button
          buttonType={ButtonTypes.Primary}
          onClick={() => handleOpenModalActions(null)}
          style={{ height: 40 }}
        >
          <Plus2 />
          <span>NOVO E-MAIL</span>
        </Button>
      </div>

      {/* Emails */}
      <Table
        data={recurringEmails?.emailList ?? []}
        columns={tableColumnsEmail}
        showFooter={true}
        dataCount={recurringEmails?.emailListSize ?? null}
        fetchMessages={fetchEmails}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoadingRecurringEmails}
      />

      {/* Recipients */}
      <DrawerRecipients
        isOpenRecipients={isOpenRecipients}
        currentRecipients={currentRecipients}
        handleCloseModalRecipients={handleCloseModalRecipients}
      />

      {/* Form */}
      <Drawer
        open={showEmailDrawer}
        onClose={handleCloseModalActions}
        title={
          currentEmail
            ? 'Visualizar e-mail recorrente'
            : 'Novo e-mail recorrente'
        }
      >
        <RecurringEmailForm
          currentEmail={currentEmail}
          useForm={useForm}
          createRecurringEmail={createRecurringEmail}
          handleToggleStatus={handleToggleStatus}
          editRecurringEmail={editRecurringEmail}
          isLoading={recurringEmails.loading}
        />
      </Drawer>

      {/* Change Log */}
      <Drawer
        open={isOpenChangeLog}
        onClose={handleCloseModalChangeLog}
        title='Histórico de alterações'
      >
        <Table
          data={currentChangeLog.slice(
            (changeLogPage - 1) * 5,
            (changeLogPage - 1) * 5 + 5
          )}
          columns={tableColumnsChangeLog}
          showFooter={true}
          dataCount={currentChangeLog.length}
          fetchMessages={getChangeLog}
          currentPage={changeLogPage}
          setCurrentPage={setChangeLogPage}
        />
      </Drawer>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchtoProps)(RecurringEmails);
