/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-assignment */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useForm,
  Controller,
  FieldErrorsImpl,
  SubmitHandler
} from 'react-hook-form';
import {
  addDays,
  format,
  getDay,
  isBefore,
  isEqual,
  startOfMinute,
  startOfMonth
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Select from '@ui/Select';
import Input from '@ui/Input';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import MultipleSelectCheckmarks from '@ui/MultiSelect';
import Calendar from '@ui/Calendar';
import { getClassList, postUploadPublicImage } from '@utils/ajax';
import { UserEmails, getUsersByEmail } from '@utils/api/emails';
import AsyncSelect from '@ui/AsyncSelect';
import Switch from '@ui/Switch';
import { escapeRegExp } from 'lodash';
import RichTextEditor from '@ui/RichTextEditor';
import { setLoadingRecurringEmails } from '@redux/actions';
import { ClassOption } from '../../../../messages/form';
import Calendar2 from '../../../../../../assets/icons/calendar2';
import CustomRecurringModal from '../custom-recurring-modal';
import {
  RecurringFormData,
  CustomRecurrence,
  RecurringEmail as Email,
  EmailRecipients,
  EmailRecipientsTypeOptions,
  FixedEmails,
  RecurringOption,
  RecurringTypeOptions,
  RegionalDepartment,
  TargetGroup,
  TargetGroupOptions
} from '../../types';
import RenderOptions from './render-options';
import RenderInput from './render-input';

type RecurringEmailForm = {
  readonly currentEmail: Email | null;
  readonly useForm: typeof useForm;
  readonly createRecurringEmail: (data: Email) => void;
  readonly handleToggleStatus: (isActive: boolean, id: string) => void;
  readonly editRecurringEmail: (data: Email) => void;
  readonly setLoadingRecurringEmails: (loading: boolean) => void;
  readonly isLoading: boolean;
};

type Option = {
  value: string;
  option: string;
};

const mapDispatchtProps = { setLoadingRecurringEmails };

const recipientOptions: Option[] = [
  {
    value: 'fixedEmails',
    option: 'E-mails fixos'
  },
  {
    value: 'targetGroup',
    option: 'Grupo-alvo'
  },
  {
    value: 'regionalDepartment',
    option: 'Departamento regional'
  }
];

const targetGroupOptions = [
  { value: 'all', option: 'Todos' },
  { value: 'class', option: 'Turma' },
  { value: 'score', option: 'Pontuação' },
  { value: 'class-and-score', option: 'Turma + Pontuação' }
];

const regionalDepartmentOptions = [
  { value: 'PI', label: 'Piauí' },
  { value: 'PR', label: 'Paraná' }
];

const getRecurringOptions = (recurringDate: Date): Option[] => {
  function getWeekdayOccurrenceString(date: Date): string {
    const dayOfWeek = getDay(date);
    const firstDayOfMonth = startOfMonth(date);
    let day = firstDayOfMonth;
    let occurrence = 0;

    const ordinalNumbers = [
      'primeiro(a)',
      'segundo(a)',
      'terceiro(a)',
      'quarto(a)',
      'quinto(a)'
    ];

    while (day.getMonth() === date.getMonth()) {
      if (getDay(day) === dayOfWeek) {
        occurrence += 1;
        if (day.getDate() === date.getDate()) {
          const weekdayName = format(date, 'eeee', { locale: ptBR });
          return `no(a) ${ordinalNumbers[occurrence - 1]} ${weekdayName}`;
        }
      }
      day = addDays(day, 1);
    }

    return 'Data inválida';
  }

  const getOptonsLabel = (value: string): string => {
    switch (value) {
      case 'everyday':
        return 'Todos os dias';
      case 'weekdays':
        return 'Dias da semana (segunda à sexta-feira)';
      case 'weekly':
        return (
          'Semanal: cada ' + format(recurringDate, 'EEEE', { locale: ptBR })
        );
      case 'monthly':
        return 'Mensalmente: ' + getWeekdayOccurrenceString(recurringDate);
      case 'custom':
        return 'Personalizado';
      default:
        return '';
    }
  };

  const recurringOptions: Option[] = [
    { value: 'everyday', option: getOptonsLabel('everyday') },
    {
      value: 'weekdays',
      option: getOptonsLabel('weekdays')
    },
    { value: 'weekly', option: getOptonsLabel('weekly') },
    {
      value: 'monthly',
      option: getOptonsLabel('monthly')
    },
    { value: 'custom', option: getOptonsLabel('custom') }
  ];

  return recurringOptions;
};

function RecurringEmailForm({
  currentEmail,
  useForm,
  createRecurringEmail,
  handleToggleStatus,
  editRecurringEmail,
  setLoadingRecurringEmails,
  isLoading
}: RecurringEmailForm): JSX.Element {
  const [classOptionsList, setClassOptionsList] = useState<ClassOption[]>([]);
  const [showCustomRecurringModal, setShowCustomRecurringModal] =
    useState(false);
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [isCarbonCopySelected, setIsCarbonCopySelected] = useState(false);
  const [isBlindCarbonCopySelected, setIsBlindCarbonCopySelected] =
    useState(false);
  const [imageFiles, setImageFiles] = useState<
    { file: File; tempUrl: string; markedForUpload: boolean }[]
  >([]);
  const [messageError, setMessageError] = useState<string>('');

  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    register,
    formState: { errors },
    control
  } = useForm<RecurringFormData>();

  useEffect(() => {
    const fetchData = async () => {
      const result: string[] = await getClassList();
      setClassOptionsList(
        result
          .filter(item => !item.startsWith('nacional_'))
          .map((item: string) => ({ value: item, option: item }))
      );
    };

    void fetchData();

    if (currentEmail) {
      const {
        recipients,
        subject,
        emailContent,
        startAt,
        recurring,
        isActive = true
      } = currentEmail;
      setValue('recipients.type', recipients.type);

      switch (recipients.type) {
        case 'fixedEmails':
          setValue('recipients.fixedEmailsList', recipients.fixedEmailsList);

          if (recipients.carbonCopyEmailsList) {
            setValue(
              'recipients.carbonCopyEmailsList',
              recipients.carbonCopyEmailsList
            );
            setIsCarbonCopySelected(true);
          }

          if (recipients.blindCarbonCopyEmailsList) {
            setValue(
              'recipients.blindCarbonCopyEmailsList',
              recipients.blindCarbonCopyEmailsList
            );
            setIsBlindCarbonCopySelected(true);
          }

          break;
        case 'targetGroup':
          setValue('recipients.targetGroup', recipients.targetGroup);

          if (
            recipients.targetGroup === 'class' ||
            recipients.targetGroup === 'class-and-score'
          ) {
            setValue('recipients.class', recipients.class);
          }

          if (
            recipients.targetGroup === 'score' ||
            recipients.targetGroup === 'class-and-score'
          ) {
            setValue('recipients.minScore', recipients.minScore);
            setValue('recipients.maxScore', recipients.maxScore);
          }

          break;
        case 'regionalDepartment':
          setValue(
            'recipients.regionalDepartment',
            recipients.regionalDepartment
          );
          break;
      }

      setValue('subject', subject);
      setValue('emailContent', emailContent);
      setValue('startAt', startAt);
      setValue('recurring', recurring);
      setValue('isActive', isActive);
    }
  }, [currentEmail, setValue]);

  const type = watch('recipients.type');
  const targetGroup = watch('recipients.targetGroup');
  const startAt = watch('startAt');
  const regionalDepartment = watch('recipients.regionalDepartment');
  const customRecurring = watch('recurring.custom');
  const recurringType = watch('recurring.type');
  const fixedEmailsList = watch('recipients.fixedEmailsList');
  const carbonCopyEmailsList = watch('recipients.carbonCopyEmailsList');
  const blindCarbonCopyEmailsList = watch(
    'recipients.blindCarbonCopyEmailsList'
  );
  const isActive = watch('isActive');
  const emailContent = watch('emailContent');
  const disabled = !!currentEmail;

  const handleOpenCustomRecurringModal = () => {
    setShowCustomRecurringModal(true);
  };

  const handleCloseCustomRecurringModal = () => {
    setShowCustomRecurringModal(false);
  };

  const handleOpenCalendarModal = () => {
    setMessageError('');
    setCalendarModalIsOpen(true);
  };

  const handleCloseCalendarModal = () => {
    setCalendarModalIsOpen(false);
  };

  const handleChooseRecurringDate = (date: Date) => {
    setValue('startAt', date);
    if (date) {
      clearErrors('startAt');
    }
  };

  const handleChooseCustomRecurrence = (customRecurring: CustomRecurrence) => {
    setValue('recurring.custom', customRecurring);
  };

  const handleImageUpload = (file: File, tempUrl: string) => {
    setImageFiles(prev => [...prev, { file, tempUrl, markedForUpload: true }]);
  };

  const handleEditorChange = (content: string) => {
    setValue('emailContent', content);

    setImageFiles(prevFiles =>
      prevFiles.map(imageFile => {
        const { tempUrl } = imageFile;
        const isImageStillPresent = content.includes(tempUrl);
        return {
          ...imageFile,
          markedForUpload: isImageStillPresent
        };
      })
    );
  };

  const validateDate = (date: Date) => {
    const now = startOfMinute(new Date());
    const isBeforeOrEqual =
      isBefore(startOfMinute(new Date(date)), now) ||
      isEqual(startOfMinute(new Date(date)), now);
    return isBeforeOrEqual;
  };

  const onSubmit: SubmitHandler<RecurringFormData> = async (
    data: RecurringFormData
  ) => {
    setLoadingRecurringEmails(true);
    try {
      if (validateDate(data.startAt)) {
        setMessageError(
          'Data e hora para programação do e-mail devem ser posteriores ao momento atual.'
        );
        return;
      }
      const imagesToUpload = imageFiles.filter(
        imageFile => imageFile.markedForUpload
      );

      const uploadedImageUrls = await Promise.all(
        imagesToUpload.map(async ({ file }) => {
          const response = await postUploadPublicImage(file);
          return response.data.result.fileUrl;
        })
      );

      let updatedContent = emailContent;
      imagesToUpload.forEach(({ tempUrl }, index) => {
        const realUrl = uploadedImageUrls[index];

        const escapedTempUrl = escapeRegExp(tempUrl);

        updatedContent = updatedContent.replace(
          new RegExp(escapedTempUrl, 'g'),
          realUrl
        );
      });

      if (data.recurring.type !== 'custom' && data.recurring.custom) {
        delete data.recurring.custom;
      }

      if (currentEmail) {
        const emailPayload: Email = {
          ...currentEmail,
          ...data,
          emailContent: updatedContent
        };

        editRecurringEmail({ id: currentEmail.id, ...emailPayload });
        return;
      }

      const emailPayload: Email = {
        ...data,
        createdAt: new Date(),
        changeLog: [],
        status: 'vigente',
        emailContent: updatedContent,
        isActive: typeof data.isActive === 'boolean' ? data.isActive : true
      };
      createRecurringEmail(emailPayload);
    } catch (error) {
      console.error('Erro ao criar e-mail recorrente:', error);
    } finally {
      setLoadingRecurringEmails(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='recipients.type'
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field: { name, value, onChange } }) => (
          <Select
            label='Destinatário'
            placeholder='Selecione'
            defaultValue=''
            options={recipientOptions}
            name={name}
            value={value}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const value = event.target.value as EmailRecipientsTypeOptions;
              setValue('recipients', { type: value } as EmailRecipients);
              onChange(value);
            }}
            error={
              (errors.recipients as FieldErrorsImpl<EmailRecipients>)?.type
                ?.message
            }
            disabled={disabled}
          />
        )}
      />

      {['fixedEmails'].includes(type) && (
        <>
          <AsyncSelect
            getOptionLabel={(option: UserEmails) => option.email}
            getOptionKey={(option: unknown) => (option as { id: string }).id}
            defaultValue={fixedEmailsList}
            renderOption={(props, option: UserEmails) => (
              <RenderOptions option={option} {...props} />
            )}
            renderInput={params => (
              <RenderInput
                params={params}
                hasCarbonCopy
                isCarbonCopySelected={isCarbonCopySelected}
                onClickCarbonCopy={() => setIsCarbonCopySelected(prev => !prev)}
                isBlindCarbonCopySelected={isBlindCarbonCopySelected}
                onClickBlindCarbonCopy={() =>
                  setIsBlindCarbonCopySelected(prev => !prev)
                }
              />
            )}
            handleSearch={getUsersByEmail}
            label='E-mails'
            {...register('recipients.fixedEmailsList', {
              validate: value => {
                return (
                  (Array.isArray(value) && value.length > 0) ||
                  'Campo obrigatório'
                );
              }
            })}
            onChange={(_e, value: UserEmails[]) =>
              setValue('recipients.fixedEmailsList', value)
            }
            error={
              (errors.recipients as FieldErrorsImpl<FixedEmails>)
                ?.fixedEmailsList?.message
            }
            disabled={disabled}
          />

          {isCarbonCopySelected && (
            <AsyncSelect
              getOptionLabel={(option: UserEmails) => option.email}
              getOptionKey={(option: unknown) => (option as { id: string }).id}
              defaultValue={carbonCopyEmailsList}
              renderOption={(props, option: UserEmails) => (
                <RenderOptions option={option} {...props} />
              )}
              renderInput={params => <RenderInput params={params} />}
              handleSearch={getUsersByEmail}
              label='Cópia de e-mail'
              {...register('recipients.carbonCopyEmailsList')}
              onChange={(_e, value: UserEmails[]) =>
                setValue('recipients.carbonCopyEmailsList', value)
              }
              error={
                (errors.recipients as FieldErrorsImpl<FixedEmails>)
                  ?.carbonCopyEmailsList?.message
              }
              disabled={disabled}
            />
          )}

          {isBlindCarbonCopySelected && (
            <AsyncSelect
              getOptionLabel={(option: UserEmails) => option.email}
              getOptionKey={(option: unknown) => (option as { id: string }).id}
              defaultValue={blindCarbonCopyEmailsList}
              renderOption={(props, option: UserEmails) => (
                <RenderOptions option={option} {...props} />
              )}
              renderInput={params => <RenderInput params={params} />}
              handleSearch={getUsersByEmail}
              label='Cópia de e-mail oculta'
              {...register('recipients.blindCarbonCopyEmailsList')}
              onChange={(_e, value: UserEmails[]) =>
                setValue('recipients.blindCarbonCopyEmailsList', value)
              }
              error={
                (errors.recipients as FieldErrorsImpl<FixedEmails>)
                  ?.blindCarbonCopyEmailsList?.message
              }
              disabled={disabled}
            />
          )}
        </>
      )}

      {['targetGroup'].includes(type) && (
        <Controller
          control={control}
          name='recipients.targetGroup'
          rules={{
            required: {
              value: type === 'targetGroup',
              message: 'Campo obrigatório'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              label='Grupo-alvo'
              placeholder='Selecione'
              defaultValue=''
              options={targetGroupOptions}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const value = event.target.value as TargetGroupOptions;
                setValue('recipients', {
                  type: 'targetGroup',
                  targetGroup: value
                });
                onChange(value);
              }}
              value={value}
              error={
                (errors.recipients as FieldErrorsImpl<TargetGroup>)?.targetGroup
                  ?.message
              }
              disabled={disabled}
            />
          )}
        />
      )}

      {type === 'targetGroup' && (
        <>
          {['class', 'class-and-score'].includes(targetGroup) && (
            <Controller
              control={control}
              name='recipients.class'
              rules={{
                required: {
                  value:
                    type === 'targetGroup' &&
                    (targetGroup === 'class' ||
                      targetGroup === 'class-and-score'),
                  message: 'Campo obrigatório'
                }
              }}
              render={({ field }) => (
                <Select
                  label='Turma'
                  placeholder='Selecione'
                  defaultValue=''
                  options={classOptionsList}
                  onChangeSelect={(event: ChangeEvent<HTMLSelectElement>) => {
                    setValue('recipients.class', event.target.value);
                  }}
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    (errors.recipients as FieldErrorsImpl<TargetGroup>)?.class
                      ?.message
                  }
                  disabled={disabled}
                />
              )}
            />
          )}

          {['score', 'class-and-score'].includes(targetGroup) && (
            <div className='form-line'>
              <Controller
                control={control}
                name='recipients.minScore'
                rules={{
                  required: {
                    value:
                      type === 'targetGroup' &&
                      (targetGroup === 'score' ||
                        targetGroup === 'class-and-score'),
                    message: 'Campo obrigatório'
                  }
                }}
                render={({ field }) => (
                  <Input
                    id='email-min-score'
                    label='Pontuação mínima'
                    placeholder='Pontuação mínima'
                    type='number'
                    style={{ flex: 1 }}
                    {...field}
                    error={
                      (errors.recipients as FieldErrorsImpl<TargetGroup>)
                        ?.minScore?.message
                    }
                    disabled={disabled}
                  />
                )}
              />

              <Controller
                control={control}
                name='recipients.maxScore'
                rules={{
                  required: {
                    value:
                      type === 'targetGroup' &&
                      (targetGroup === 'score' ||
                        targetGroup === 'class-and-score'),
                    message: 'Campo obrigatório'
                  }
                }}
                render={({ field }) => (
                  <Input
                    id='email-max-score'
                    label='Pontuação máxima'
                    placeholder='Pontuação máxima'
                    type='number'
                    style={{ flex: 1 }}
                    {...field}
                    error={
                      (errors.recipients as FieldErrorsImpl<TargetGroup>)
                        ?.maxScore?.message
                    }
                    disabled={disabled}
                  />
                )}
              />
            </div>
          )}
        </>
      )}

      {['regionalDepartment'].includes(type) && (
        <Controller
          control={control}
          name='recipients.regionalDepartment'
          rules={{
            required: {
              value: type === 'regionalDepartment',
              message: 'Campo obrigatório'
            },
            min: {
              value: 1,
              message: 'Selecione pelo menos um item'
            }
          }}
          render={({ field }) => (
            <MultipleSelectCheckmarks
              options={regionalDepartmentOptions}
              selectedOptions={regionalDepartment?.map(item => {
                const option = regionalDepartmentOptions.find(
                  option => option.value === item
                );
                return {
                  label: option?.label ?? item,
                  value: item
                };
              })}
              {...field}
              onChange={newValue => {
                if (newValue.length > 0) {
                  clearErrors('recipients.regionalDepartment');
                } else {
                  setError('recipients.regionalDepartment', {
                    type: 'required',
                    message: 'Campo obrigatório'
                  });
                }
                setValue(
                  'recipients.regionalDepartment',
                  newValue.map(item => item.value)
                );
              }}
              error={
                (errors.recipients as FieldErrorsImpl<RegionalDepartment>)
                  ?.regionalDepartment?.message
              }
              disabled={disabled}
            />
          )}
        />
      )}

      <Controller
        name='subject'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field }) => (
          <Input
            label='Assunto'
            placeholder='Assunto'
            type='text'
            error={errors.subject?.message}
            disabled={disabled}
            {...field}
          />
        )}
      />

      <Controller
        name='emailContent'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field: { value } }) => (
          <>
            <RichTextEditor
              error={errors.emailContent?.message}
              defaultValue={value}
              onChange={handleEditorChange}
              onImageUpload={handleImageUpload}
              disabled={disabled}
            />
            {messageError && (
              <span className='error-message'>{messageError}</span>
            )}
          </>
        )}
      />

      <span className='drawer-title-in-form'>Recorrência</span>

      <div className='form-line'>
        <Controller
          control={control}
          name='startAt'
          rules={{
            required: {
              value: true,
              message: 'Campo obrigatório'
            }
          }}
          render={({ field }) => (
            <Input
              id='email-start-at'
              placeholder='Selecione'
              type='text'
              style={{ flex: 1 }}
              insideIcon={
                <Calendar2
                  onClick={() => {
                    if (disabled) return;
                    handleOpenCalendarModal();
                  }}
                />
              }
              onClick={handleOpenCalendarModal}
              {...field}
              value={
                startAt
                  ? format(
                      new Date(startAt),
                      "dd 'de' MMM., 'de' yyyy 'as' hh:mm",
                      {
                        locale: ptBR
                      }
                    )
                  : ''
              }
              readOnly
              error={errors.startAt?.message}
              disabled={disabled || isLoading}
            />
          )}
        />

        <Controller
          control={control}
          name='recurring.type'
          rules={{
            required: {
              value: true,
              message: 'Campo obrigatório'
            }
          }}
          render={({ field }) => {
            const recurringOptions = getRecurringOptions(
              startAt ? new Date(startAt) : new Date()
            );

            return (
              <Select
                options={recurringOptions}
                style={{ flex: 1 }}
                placeholder='Selecione'
                defaultValue=''
                value={field.value}
                onClick={() => {
                  if (recurringType === 'custom') {
                    handleOpenCustomRecurringModal();
                  }
                }}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  const value = event.target.value as RecurringTypeOptions;

                  if (!value.length) {
                    setError('recurring.type', {
                      type: 'required',
                      message: 'Campo obrigatório'
                    });
                  } else {
                    clearErrors('recurring.type');
                  }

                  if (value !== 'custom' && customRecurring) {
                    setValue('recurring.custom', undefined);
                    return;
                  }
                  setValue('recurring.type', value);

                  const recurringOption =
                    recurringOptions.find(option => option.value === value)
                      ?.option ?? '';
                  setValue('recurringDescription', recurringOption);
                  value === 'custom' && handleOpenCustomRecurringModal();
                }}
                error={
                  (errors.recurring as FieldErrorsImpl<RecurringOption>)?.type
                    ?.message
                }
                disabled={disabled || isLoading}
              />
            );
          }}
        />
      </div>

      <div className='drawer-footer'>
        <div>
          <Switch
            id='my-test'
            onChange={e => {
              if (currentEmail?.id) {
                handleToggleStatus(e.target.checked, currentEmail.id);
              }
              setValue('isActive', e.target.checked);
            }}
            checked={isActive === undefined || isActive}
            disabled={isLoading}
          />
          <span>
            {isActive === undefined || isActive ? 'Ativo' : 'Inativo'}
          </span>
        </div>

        <Button
          buttonType={ButtonTypes.Primary}
          style={{ height: 34 }}
          disabled={disabled || isLoading}
          isLoading={isLoading}
        >
          Agendar
        </Button>
      </div>

      <CustomRecurringModal
        show={showCustomRecurringModal}
        handleClose={handleCloseCustomRecurringModal}
        onChoose={handleChooseCustomRecurrence}
        startAt={startAt}
        customRecurrence={customRecurring}
      />

      <Calendar
        show={calendarModalIsOpen}
        handleClose={handleCloseCalendarModal}
        value={startAt ? new Date(startAt) : new Date()}
        onChoose={handleChooseRecurringDate}
      />
    </form>
  );
}

export default connect(null, mapDispatchtProps)(RecurringEmailForm);
